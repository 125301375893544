import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Events.css";
import './Home.css';
import {THEME_COLORS} from '../constants/Constants';
import 'swiper/css'; // Core Swiper styles
import 'swiper/css/navigation'; // Navigation module styles (if using navigation)
import 'swiper/css/pagination'; // Pagination module styles (if using pagination)
import { Navigation } from "swiper/modules"; // ✅ Import Navigation Module

const Events = () => {
    const swiperRef = useRef(null); // ✅ Store Swiper instance
    const nextRef = useRef(null);
    const prevRef = useRef(null);
    const [cards, setCards] = useState([
        { 
            id: 6, image: "https://i.imgur.com/SUHNI1O.png", 
            title: (
                <>
                  2025 McGill Chinese Orchestra <br />Fleeting Melodies Concert
                </>
              ),
            description: (
                <>
                2025 MCO Fleeting Melodies concert is a celebration of musical diversity, blending the rich 
                traditions of Chinese music into a mesmerizing concert experience. 
                From the delicate elegance of Chinese classical melodies to the powerful resonance
                of Western orchestral arrangements, each performance highlights the beauty of cultural fusion.
                With a dynamic mix of instrumental ensembles, and modern interpretations,
                this concert brings together musicians from different backgrounds to create a harmonious artistic dialogue.
                Join us for an unforgettable evening of music, passion, and cross-cultural connection as we explore the 
                fleeting yet timeless beauty of melodies that transcend borders.
                </>
             ),
            date: (
                <>
                February 15, 2025 <br />
                <strong>Salle Sylvain Lelièvre, Collège Maisonneuve</strong>
                </>
            )
        },
        { 
            id: 5, 
            image: "https://i.imgur.com/TJR8BJS.jpg", 
            title: (
                <>
                  2020 McGill Chinese Orchestra <br />The Legend of Mulan Concert
                </>
              ),
            description: (
                <>
                The Legend of Mulan—a musical tribute to one of China’s most iconic heroines. 
                Through the evocative sounds of traditional Chinese instruments, we bring Mulan’s 
                legendary journey to life, painting her bravery, loyalty, and sacrifice in every note.
                With music as our brush, we honor her story—one of duty, resilience, and the courage to 
                defy expectations. Join us as we embark on this epic journey alongside Mulan, marching 
                across a thousand miles in a symphony of strength and spirit.
                </>
            ),
            date: (
                <>
                February, 2020 <br />
                <strong>Le Gesù</strong>
                </>
            )
        },
        { 
            id: 4, 
            image: "https://i.imgur.com/WHqwG2c.jpg", 
            title: (
                <>
                  2019 McGill Chinese Orchestra <br />Life's But A Dream Concert
                </>
              ),
            description: (
                <>
                Life’s But a Dream is a musical journey 
                through the dreams and choices that shape our lives. Each 
                person carries unique experiences, each heart holds different 
                reflections, and every decision we make is influenced by our own inner world.
                Through music, we paint the essence of life’s greatest desires—friendship, 
                power, and love—inviting you to explore the emotions and moments that define us all.
                Life is never a solo act but a shared experience, a dream we all navigate together.
                </>
            ),
            date: (
                <>
                January 20, 2025 <br />
                <strong>D.B. Clarke Theatre, Concordia University</strong>
                </>
            )
        },
        { 
            id: 3, 
            image: "https://i.imgur.com/kXOTyLT.jpg", 
            title: (
                <>
                  2025 Concordia CSSA <br />Spring Festival Gala
                </>
              ),
            description: (
                <>
                  A vibrant fusion of East and West, showcasing international students' energy and cultural exchange.
                  From Peking Opera to K-pop, Chinese melodies to Western orchestras, each act is crafted for an unforgettable
                  experience. With music, dance, and comedy, this artistic feast welcomes 2025 with creativity and passion!
                </>
              ),
            date: "February 8, 2025" 
        },
        { 
            id: 2, 
            image: "https://i.imgur.com/5zAPqTd.jpg", 
            title: (
                <>
                  2025 Consulate General of China in Montreal<br />
                  CNY Reception at ICAO Headquarters
                </>
              ),
            description: (
                <>
                    The 2025 Chinese New Year Reception, hosted by the Consulate General 
                    of the People's Republic of China in Montreal at ICAO Headquarters, was 
                    a joyous celebration of Chinese New Year. Featuring vibrant performances,
                     traditional music, and a gathering of distinguished guests, the event 
                     highlighted the richness of Chinese culture while fostering friendship and unity. 
                     It was a memorable evening of festivity, cultural exchange, and new beginnings.
                </>
            ), 
            date: "January 22, 2025" 
        },
        { 
            id: 1, 
            image: "https://i.imgur.com/oHlqhfY.jpg", 
            title: (
                <>
                  2024 Parliament Hill <br />Ottawa Chinese Music and Opera Festival
                </>
              ),
              description: (
                <>
                  Our Parliament Hill Performance was a remarkable celebration 
                  of cultural harmony, bringing the rich sounds of traditional 
                  Chinese music to Canada’s iconic landmark. From the soulful guzheng
                  to the lively erhu and pipa, each piece showcased the beauty of
                  Chinese musical heritage and cross-cultural exchange.
                  A heartfelt thank you to Mr. Chandra Arya, MP, and the Ottawa
                  Chinese Musicians' Association for organizing this special event. 
                  It was an unforgettable experience, uniting music and culture in a truly inspiring setting.
                </>
              ),
            date: "November 26, 2024" 
        },
    ]);
    const latestEventID = Math.max(...cards.map(card => card.id));
    const [hoveredCard, setHoveredCard] = useState(null);
    const [activeEvent, setActiveEvent] = useState(null); // Track the clicked event
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        if (swiperRef.current?.swiper) {
            swiperRef.current.swiper.params.navigation.nextEl = nextRef.current;
            swiperRef.current.swiper.params.navigation.prevEl = prevRef.current;
            swiperRef.current.swiper.navigation.init();
            swiperRef.current.swiper.navigation.update();
        }
    }, [isMobile]);
    
    
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

    // Moves clicked card to top (but doesn't open modal)
    const handleCardClick = (id) => {
        const clickedCardIndex = cards.findIndex((card) => card.id === id);
        const newOrder = [
            cards[clickedCardIndex],
            ...cards.slice(clickedCardIndex + 1),
            ...cards.slice(0, clickedCardIndex),
        ];
        setTimeout(() => {
            setCards(newOrder);
        }, 200);
    };

    // Opens modal without affecting card order
    const handleEventClick = (id, event) => {
        event.stopPropagation(); // Prevents triggering handleCardClick when clicking the button
        const clickedEvent = cards.find((card) => card.id === id);
        setActiveEvent(clickedEvent);
    };

    const handleHoveredCard = (id) => {
        setHoveredCard(id); // Set the hovered card
    };

    return (
        <div className="events-container">
            {isMobile ? (
            <>
            <div>
                <h2 className="events-title">Our Events</h2>
            </div>
            <Swiper
                className="swiper"
                ref={swiperRef}
                modules = {[Navigation]}
                spaceBetween={60}
                slidesPerView={1}
                navigation
                loop={true} 
            >
            {cards.map((card) => (
                <SwiperSlide key={card.id}>
                <div className="event-card">
                    <img src={card.image} alt="Event" className="event-image" />
                    <h3 className="event-title">{card.title}</h3>
                    <p className="event-description">{card.description}</p>
                    {card.id === latestEventID && (
                        <button
                            className="close-button"
                            onClick={() => window.location.href = "https://www.eventbrite.ca/e/fleeting-melodies-mcgill-chinese-orchestra-2025-spring-concert-tickets-1110986493509?aff=oddtdtcreator"}
                            style={{
                                backgroundColor: THEME_COLORS.MCO_RED,
                                width: "100%",
                            }}
                            onMouseEnter={(e) => e.target.style.backgroundColor = THEME_COLORS.MCO_LIGHT_RED}
                            onMouseLeave={(e) => e.target.style.backgroundColor = THEME_COLORS.MCO_RED}
                        >
                            Buy Tickets
                        </button>
                    )}
                </div>
                </SwiperSlide>
            ))}
            </Swiper>
            </>
            ) : (
            <div className="events-carousel">
                {cards.map((card, index) => {
                    const isHovered = hoveredCard === card.id;
                    const zIndex = cards.length - index;
                    const translateXOriginal = -140 + index * 70; // Adjust X offset
                    const scaleOriginal = 1 - index * 0.2; // Adjust scale
                    const scaleHovered = isHovered ? 1.1 : 1;
                    const translateHovered = isHovered ? 5 : 0;
                    const translateX = translateXOriginal + translateHovered;
                    const scale = scaleOriginal + scaleHovered;

                    return (
                        <div
                            key={card.id}
                            className="card"
                            style={{
                                backgroundImage: `url(${card.image})`,
                                zIndex,
                                transform: `translate(${translateX}%) scale(${scale})`,
                                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                            }}
                            onMouseEnter={() => {
                                if (index !== 0) handleHoveredCard(card.id);
                            }}
                            onMouseLeave={() => setHoveredCard(null)}
                            onClick={() => handleCardClick(card.id)}
                        >
                            <h3 className="card-title">{card.title}</h3>
                            <button className="view-details-button" onClick={(e) => handleEventClick(card.id, e)}>
                                More Details
                            </button>
                        </div>
                    );
                })}
            </div>
            )}
            {/* Modal Component */}
            {activeEvent && (
                <div className="modal-overlay" onClick={() => setActiveEvent(null)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <h2>{activeEvent.title}</h2>
                        <div className="modal-body">
                            <p><strong>Date:</strong> {activeEvent.date}</p>
                            <p>{activeEvent.description}</p>
                        </div>
                        <button
                            className="close-button"
                            onClick={() => setActiveEvent(null)}
                            style={{
                                backgroundColor: THEME_COLORS.MCO_RED, // Default background color
                                marginTop: "1.5rem",
                            }}
                            onMouseEnter={(e) => e.target.style.backgroundColor = THEME_COLORS.MCO_LIGHT_RED} // Hover color
                            onMouseLeave={(e) => e.target.style.backgroundColor = THEME_COLORS.MCO_RED} // Revert on leave
                        >
                            Close
                        </button>
                        {activeEvent.id === latestEventID && (
                            <button
                                className="close-button"
                                onClick={() => window.location.href = "https://www.eventbrite.ca/e/fleeting-melodies-mcgill-chinese-orchestra-2025-spring-concert-tickets-1110986493509?aff=oddtdtcreator"}
                                style={{
                                    backgroundColor: THEME_COLORS.MCO_RED, // Default background color
                                }}
                                onMouseEnter={(e) => e.target.style.backgroundColor = THEME_COLORS.MCO_LIGHT_RED} // Hover color
                                onMouseLeave={(e) => e.target.style.backgroundColor = THEME_COLORS.MCO_RED} // Revert on leave
                            >
                                Buy Tickets
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Events;
