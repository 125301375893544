import React, { useEffect, useState } from 'react';
import './Background.css';

function Background({route}) {
    const backgroundImages = {
        '/': [
            "url('https://i.imgur.com/bADpOJD.jpg')",
            "url('https://i.imgur.com/YrroQL3.jpg')",
            "url('https://i.imgur.com/hOyMGWE.jpg')",
            "url('https://i.imgur.com/oHlqhfY.jpg')",
            "url('https://i.imgur.com/eJ8ctbG.jpg')",
            "url('https://i.imgur.com/524ssWe.jpg')",
            "url('https://i.imgur.com/btD5WGC.jpg')",
        ],
        '/events':[
            "url('https://i.imgur.com/bADpOJD.jpg')",
            "url('https://i.imgur.com/hOyMGWE.jpg')",
            "url('https://i.imgur.com/btD5WGC.jpg')",
            "url('https://i.imgur.com/eJ8ctbG.jpg')",
            "url('https://i.imgur.com/YrroQL3.jpg')",
        ]
    };
    const images = backgroundImages[route] || backgroundImages['/'];
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [scrollY, setScrollY] = useState(0);
    const [fadeClass, setFadeClass] = useState("fade-in");
    const [scale, setScale] = useState(1); // Track scale separately

    useEffect(() => {
      // Change background every 5 seconds
      const interval = setInterval(() => {
          setFadeClass(""); // Remove class to reset animation
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            setFadeClass("fade-in"); // Re-add class to restart animation
        }, 50);
      }, 5000);

      return () => clearInterval(interval);
  }, [images]);

  useEffect(() => {
    const handleScroll = () => {
        const newScale = 1 + window.scrollY * 0.0015; // Scale based on scroll
        setScale(newScale);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
}, []);
    return (
      <>
        <div className="background-container">
          <div
            className={`background-layer ${fadeClass}`}
            style={{
                backgroundImage: images[currentIndex],
                transform: `scale(${scale})`,
            }}
          />
        </div>
      </>
    );
  }

  export default Background;
  