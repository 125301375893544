import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';
import './Navigation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faFacebook, faInstagram, faBilibili } from '@fortawesome/free-brands-svg-icons';
import { SiXiaohongshu, SiWechat } from 'react-icons/si';

function Navigation() {
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const goToEmails = () => {
        const email = "mco@ssmu.ca";
        const subject = encodeURIComponent("Inquiry about the McGill Chinese Orchestra");
        window.location.href = `mailto:${email}?subject=${subject}`;
        setShowOffcanvas(false);
    };

    return (
        <Navbar bg="transparent" variant="dark" expand="lg" fixed="top">
            <Container>
                <Navbar.Brand as={NavLink} to="/" className="navbar-home">
                    <img
                        src="/assets/icons/icon-mco.svg"
                        alt="Brand Logo"
                        width="125"
                        height="125"
                    />
                </Navbar.Brand>
                <Navbar.Toggle onClick={() => setShowOffcanvas(true)} />

                <Navbar.Offcanvas
                    show={showOffcanvas}
                    onHide={() => setShowOffcanvas(false)}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title 
                            className='navbar-title'
                            onClick={() => setShowOffcanvas(false)}
                        >
                            Menu
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="me-auto">
                            <Nav.Link 
                                as={NavLink} 
                                to="/" 
                                className="navbar-link" 
                                onClick={() => setShowOffcanvas(false)}
                            >
                                Home
                            </Nav.Link>
                            <Nav.Link 
                                as={NavLink} 
                                to="/events" 
                                className="navbar-link" 
                                onClick={() => setShowOffcanvas(false)}
                            >
                                Events
                            </Nav.Link>
                            <Nav.Link onClick={goToEmails} className="navbar-link">
                                Contact
                            </Nav.Link>
                            <Nav className="social-icon-container">
                                <a href="https://www.youtube.com/channel/UC57hHM5J83Xr62Zj1XOrarw" target="_blank" rel="noopener noreferrer" className="social-icon">
                                    <FontAwesomeIcon icon={faYoutube} size="lg" />
                                </a>
                                <a href="https://www.facebook.com/mcgillstudentschinesemusicsociety/" target="_blank" rel="noopener noreferrer" className="social-icon">
                                    <FontAwesomeIcon icon={faFacebook} size="lg" />
                                </a>
                                <a href="https://www.instagram.com/mco_mcgill" target="_blank" rel="noopener noreferrer" className="social-icon">
                                    <FontAwesomeIcon icon={faInstagram} size="lg" />
                                </a>
                                <a href="https://space.bilibili.com/3493290609478210" target="_blank" rel="noopener noreferrer" className="social-icon">
                                    <FontAwesomeIcon icon={faBilibili} size="lg" />
                                </a>
                                <a href="https://www.xiaohongshu.com/user/profile/66dd1f01000000000d025ee4" target="_blank" rel="noopener noreferrer" className="social-icon">
                                    <SiXiaohongshu size="1.5em" />
                                </a>
                                <a href="https://mp.weixin.qq.com/s/4hxNMyhoZ32R8_-58kzk4Q" target="_blank" rel="noopener noreferrer" className="social-icon">
                                    <SiWechat size="1.5em" />
                                </a>
                            </Nav>
                        </Nav>
                        {/* Social Media Icons */}
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}
export default Navigation;
